import { RangeControl, Button, ButtonGroup, ToggleControl } from '@wordpress/components';
import  { useState ,useEffect} from 'react';
export const BackgroundBorderRadusComponent = (props) => {
    const [currentSelection, setCurrentSelection] = useState('tl')
    const [radiusValues, setradiusValues] = useState({ tl: 0, tr: 0, br: 0, bl: 0 })
    const [toggleAll, setToggleAll] = useState(true)
    const callBackTarget = props.target;
    const changeRadius = (change) => {

        if (toggleAll) {
            setradiusValues({ ...radiusValues, tl: change, tr: change, br: change, bl: change })

        } else {
            setradiusValues({ ...radiusValues, [currentSelection]: change })
        }

    }
    useEffect(()=>{props.callBack({[props.callBackTarget]: `${radiusValues.tl}% ${radiusValues.tr}% ${radiusValues.br}% ${radiusValues.bl}%`})
    },[radiusValues])
    return (<>
        <h4>{props.title}</h4>
        <h4>Please Set Overlay and Background colour to the same value before setting border radius</h4>
        <ToggleControl
            label="Toggle All Corners"
            //help=
            checked={toggleAll}
            onChange={(() => setToggleAll((toggleAll) => !toggleAll))}
        />
        {!toggleAll &&
            <ButtonGroup>
                <Button className={(currentSelection == 'tl' ? 'is-primary' : 'is-secondary')} onClick={() => setCurrentSelection('tl')}>Top-Left</Button>
                <Button className={(currentSelection == 'tr' ? 'is-primary' : 'is-secondary')} onClick={() => setCurrentSelection('tr')}>Top-Right</Button>
                <Button className={(currentSelection == 'br' ? 'is-primary' : 'is-secondary')} onClick={() => setCurrentSelection('br')}>Bottom-Right</Button>
                <Button className={(currentSelection == 'bl' ? 'is-primary' : 'is-secondary')} onClick={() => setCurrentSelection('bl')}>Bottom-Left</Button>
            </ButtonGroup>
        }
        <RangeControl

            label={'Background Border Radius'}
            value={radiusValues[currentSelection]}
            onChange={(change) => changeRadius(change)}
            // default={0}
            min={0}
            max={50}
            step={5}
            marks={[
                {
                    value: 0,
                    label: '0%',
                },
                {
                    value: 5,
                    label: '5%',
                },
                {
                    value: 10,
                    label: '10%',
                },

                {
                    value: 20,
                    label: '20%',
                },

                {
                    value: 30,
                    label: '30%',
                },
                {
                    value: 40,
                    label: '40%',
                },
                {
                    value: 50,
                    label: '50%',
                },
            ]}
        />


    </>
    );
}

