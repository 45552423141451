import '../../library/scss/vendor/slick/slick.scss';
import domReady from '@wordpress/dom-ready'
domReady(() => {
  if(window._wpLoadBlockEditor){
  window._wpLoadBlockEditor.then(function () {

    jQuery(document).ready(function ($) {


      document.addEventListener('slideShowStart', () => {

        if (($('.tp_masthead--background-wrapper-slider').length > 0) && (!$('.slick-track').length)) {
          let $mastheadSliderGallery = $(".tp_masthead--background-wrapper-slider");
          $mastheadSliderGallery.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            rows: 0,
            arrows: false,
            dots: false,
            fade: true,
            cssEase: "linear",
            speed: 1000,
            infinite: true,
            draggable: false,
            pauseOnHover: false
          });
        }
      });
      document.addEventListener('slideShowStop', () => {
        if (($('.tp_masthead--background-wrapper-slider').length) && ($('.slick-track').length)) {
          $('.tp_masthead--background-wrapper-slider').slick('unslick');

        }
      })

    }
    );
  });
}})



