//https://css-tricks.com/how-to-use-block-variations-in-wordpress/
import { React, useState, useEffect } from 'react'
import { PanelBody } from '@wordpress/components';
import { MediaUploader } from '../../CommonComponents/MediaUploadComponent';
import { ToggleControl } from '@wordpress/components';


export const MastheadSliderSidebar = (props) => {
   const [slideAmount, setSlideAmount] = useState(props.slideAmount);
   const slideShowStart = new Event('slideShowStart');
   const slideShowStop = new Event('slideShowStop');
   const [slideShowPlaying, setSlideShowPlaying] = useState(false)
   const [currentSlideShow, setCurrentSlideShow] = useState(props.slideshow_arr);
   const [slideContentChanges, setSlideContentChanges] = useState(false);
   const changeSlideAmount = (newSliderCount) => {
      if (slideAmount < newSliderCount) { changeCurrentSlideShow(newSliderCount, '', '') };
      setSlideAmount(newSliderCount);
      props.callBack({ 'tp_masthead_slider_amount': newSliderCount })

   }


   let vaildSlides = 0;
   let allSlidesVaild = false;
   const observer = new MutationObserver((mutations, obs) => {
      const slickSlider = document.querySelector('.tp_masthead--slide');
      if (slickSlider) {
         document.dispatchEvent(slideShowStart)

         obs.disconnect();
         return;
      } else {
         return
      }
   });

   const changeCurrentSlideShow = (sliderNum, imgURL, imgAlt) => {
      setCurrentSlideShow({ ...currentSlideShow, [sliderNum]: { 'imgURL': imgURL, 'imgAlt': imgAlt } })
   }

   useEffect(() => {
      props.callBack({ 'tp_masthead_background_slideshow': currentSlideShow });
      if (slideShowPlaying) { allSlidesVaild = false; setSlideShowPlaying(false); document.dispatchEvent(slideShowStop); observer.observe(document, { childList: true, subtree: true }); setSlideShowPlaying(true) }
   }, [currentSlideShow])

   const checkSlideVaildity = (vaildSlides) => {
      if (vaildSlides === slideAmount && slideShowPlaying === false) { allSlidesVaild = true }
      useEffect(() => {
         if (allSlidesVaild === true) { observer.observe(document, { childList: true, subtree: true }); setSlideShowPlaying(true); return }
      }, [allSlidesVaild])

   }
   // useEffect(() => {
   //     //if (!slideShowPlaying) {  checkSlideVaildity(vaildSlides) }
   //     checkSlideVaildity(vaildSlides)
   //    //if (!slideShowPlaying) {observer.observe(document, { childList: true, subtree: true }); setSlideShowPlaying(true); return }
   // }, [])
   const renderSlidePicker = () => {
      let sliderPanel = [];
      for (let ix = 0; ix < slideAmount;) {
         ix++
         sliderPanel.push(
            <PanelBody title={`Select Slider Image ${ix}`} key={ix}>

               <MediaUploader
                  static_url={currentSlideShow.hasOwnProperty(ix) ? currentSlideShow[ix].imgURL :
                     js_data.guten_path + "/includes/gutenberg/masthead-block-ibtr/img/temp_background.jpg"}
                  callBack={changeCurrentSlideShow}
                  btnLabel={`Current Slide ${ix}`}
                  slideNumber={ix}
                  currentSelection={'slideshow'}
               />
               {/* {slideContentChanges &&
               <>
                  <label for={'`${ix}_prefix`'}>{`${ix}_prefix`}</label>
                  <input type={'text'} name={`${ix}_prefix`}/>
                  <label for={'`${ix}_title`'}>{`${ix}_title`}</label>
                  <input type={'text'} name={`${ix}_title`}/>
                  <label for={'`${ix}_subtitle`'}>{`${ix}_subtitle`}</label>
                  <input type={'text'} name={`${ix}_subtitle`}/>
               </>
               } */}
            </PanelBody>
         )
         // validSlideCount needs to be in state
         currentSlideShow[ix].imgURL !== "" ? vaildSlides++ : null;
      }

      checkSlideVaildity(vaildSlides)
      return sliderPanel
   }

   return (<>
      <PanelBody title="Amount Of slides">
         {/* <ToggleControl
         label="Change Text Content On Each Slide"
         checked={slideContentChanges}
         onChange={()=>{setSlideContentChanges(!slideContentChanges)}}
         /> */}
         <label htmlFor={'max_slides'}>The Maximum Amount Of Sides Is 6</label>

         <input type={'number'} name={'max_slides'} min={3} max={6} value={slideAmount}
            onChange={((e) => {
               let newSliderCount = parseInt(e.target.value); newSliderCount > 6 || newSliderCount < 3
                  ? alert('Please Choose A Number Between 3 And 6') :
                  changeSlideAmount(newSliderCount)
            })} />
      </PanelBody>
      {renderSlidePicker()}
   </>)
}

