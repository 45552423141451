import { ColorPalette, GradientPicker, RadioControl } from '@wordpress/components';
import { useState } from 'react';
export const ColourPickerComponent = (props) => {
    let target = props.target
    // let allowGradient = props.allowGradient ?? true;
    let allowGradient = true;
    const changeColour = (newColour) => { props.callBack({ [target]: newColour }); }

    const [colour, setColour] = useState(props.current)
    const [backgroundType, setBackgroundType] = useState('solid')
    const [gradient, setGradient] = useState(null);

    const colourPalette = [
        { name: 'Primary', color: 'var(--primary)' },
        { name: 'Seconary', color: 'var(--secondary)' },
        { name: 'White', color: '#fff' },
        { name: 'Black', color: '#000' },
    ];
    return (
        <>
            <h4>{props.title}</h4>
       
           {target ==="tp_masthead_overlay_colour"&& <RadioControl

                label="Select Select Overlay Type"
                selected={backgroundType}
                options={[
                    { label: 'Solid Colour', value: 'solid' },
                    { label: 'Gradient', value: 'gradient' },
                ]}
                onChange={(change) => { props.callBack({ tp_masthead_overlay_type: change }); setBackgroundType(change)  }}
            />}
            {backgroundType === "solid" && <ColorPalette
                colors={colourPalette}
                value={colour}
                onChange={(newColour) => { setColour(newColour); changeColour(newColour) }}
            />}
            {backgroundType === 'gradient' && <GradientPicker
                value={gradient}
                onChange={(currentGradient) => { changeColour(currentGradient); setGradient(currentGradient) }}
                gradients={[
                    {
                        name: 'JShine',
                        gradient:
                            'linear-gradient(135deg,#12c2e9 0%,#c471ed 50%,#f64f59 100%)',
                        slug: 'jshine',
                    },
                    {
                        name: 'Moonlit Asteroid',
                        gradient:
                            'linear-gradient(135deg,#0F2027 0%, #203A43 0%, #2c5364 100%)',
                        slug: 'moonlit-asteroid',
                    },
                    {
                        name: 'Rastafarie',
                        gradient:
                            'linear-gradient(135deg,#1E9600 0%, #FFF200 0%, #FF0000 100%)',
                        slug: 'rastafari',
                    },
                    {
                        name     : 'Vivid cyan blue to vivid purple', 
                        gradient : 'linear-gradient(135deg,rgba(6,147,227,1) 0%,rgb(155,81,224) 100%)',
                        slug     : 'vivid-cyan-blue-to-vivid-purple',
                    },
                    {
                        name     : 'Vivid green cyan to vivid cyan blue', 
                        gradient : 'linear-gradient(135deg,rgba(0,208,132,1) 0%,rgba(6,147,227,1) 100%)',
                        slug     :  'vivid-green-cyan-to-vivid-cyan-blue',
                    },
                    {
                        name     : 'Light green cyan to vivid green cyan', 
                        gradient : 'linear-gradient(135deg,rgb(122,220,180) 0%,rgb(0,208,130) 100%)',
                        slug     : 'light-green-cyan-to-vivid-green-cyan',
                    },
                    {
                        name     : 'Luminous vivid amber to luminous vivid orange', 
                        gradient : 'linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%)',
                        slug     : 'luminous-vivid-amber-to-luminous-vivid-orange',
                    },
                    {
                        name     : 'Luminous vivid orange to vivid red', 
                        gradient : 'linear-gradient(135deg,rgba(255,105,0,1) 0%,rgb(207,46,46) 100%)',
                        slug     : 'luminous-vivid-orange-to-vivid-red',
                    }
                ]}
            />}


        </>
    );
}
