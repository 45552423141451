import React from 'react';
import { __ } from '@wordpress/i18n';
import { BackgroundSwitch } from '../../CommonComponents/BackgroudSwitch';

import { useBlockProps, InnerBlocks } from '@wordpress/block-editor';


 export default function Save (props){
	const blockProps = useBlockProps.save();
	let mastheadSectionStyles = {
		justifyContent: 'center',
		backgroundColor: props.attributes.tp_masthead_background_solid_colour,
		// backgroundImage: "url(" + js_data.guten_path + "/includes/gutenberg/masthead-block-ibtr/img/temp_background.jpg)"
	}
	// This could be done via classnames. 
	if (props.attributes.tp_masthead_content_alignment !== 'center') {
		mastheadSectionStyles.justifyContent = (props.attributes.tp_masthead_content_alignment == "top") ? 'flex-start' : 'flex-end';
	}
	
	return (
		<section {...blockProps} className="align-wide tp_masthead" style={mastheadSectionStyles} >
			<BackgroundSwitch attributes={props.attributes} />


			<div className='tp_masthead--container'  >
				<InnerBlocks.Content/>
			</div>


		</section>
	);
};

// export default Save();