import {useState} from "react";
import { __ } from "@wordpress/i18n";
import { Panel, PanelBody, Button, RadioControl, ButtonGroup } from '@wordpress/components';
import { InspectorControls } from '@wordpress/block-editor';
import { BackgroundOptions } from "../../CommonComponents/BackgroudOptions";


export const MastheadIBTRSidebar = (props) => {
 
  const mastheadBackgroundOptions = {
    static: { label: 'Static Image', return: 'static' },
    slideShow: { label: 'Image Slide Show', return: 'slideShow' },
    solid: { label: 'Solid Colour', return: 'solid' },
    video: { label: 'Video', return: 'video' },
  }
  return (
    <InspectorControls>
      <Panel>
        <PanelBody title={'Masthead Background Options'}>
          <ButtonGroup>
            {Object.keys(mastheadBackgroundOptions).map((x, ix) => {
              return <Button
                onClick={() => { props.callBack({ 'tp_masthead_background_type': mastheadBackgroundOptions[x].return }) }}
                key={ix}
                variant={(props.attributes.tp_masthead_background_type == mastheadBackgroundOptions[x].return ? 'primary' : 'secondary')}
              >
                {mastheadBackgroundOptions[x].label}
              </Button>
            })}
          </ButtonGroup>


        </PanelBody>
      </Panel>
      {props.attributes.tp_masthead_background_type !== "" && <BackgroundOptions attributes={props.attributes} callBack={props.callBack} />}
      <Panel>
        <PanelBody title={'Masthead Content Alignment'}>


          <RadioControl

            label="Select Masthead Content Alignment"
            selected={props.attributes.tp_masthead_content_alignment}
            options={[
              { label: 'Top', value: 'top' },
              { label: 'Centre', value: 'center' },
              { label: 'Bottom', value: 'bottom' },
            ]}
            onChange={(change) => { props.callBack({ 'tp_masthead_content_alignment': change }) }}
          />

        </PanelBody>
        {/* <PanelBody title="Masthead Title Spacing">
        <MastheadTextSpacing callBack={props.callBack} currentClasses={props.attributes.tp_masthead_text_spacing}/>
        </PanelBody> */}
      </Panel>
    </InspectorControls>
  );
};
