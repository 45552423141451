import React from "react";
// import { MastheadMainContent } from '../masthead-block-ibtr/Components/MastheadMainContent';
import { InnerBlocks } from '@wordpress/block-editor';

export const BackgroundSwitch = (props) => {
  const {
    tp_masthead_background_type,
    tp_masthead_background_static_paralax,
    tp_masthead_overlay_colour,
    tp_masthead_overlay_opasitiy,
    tp_masthead_background_static_url,
    tp_masthead_background_static_alt,
    tp_masthead_background_static_left_pos,
    tp_masthead_background_static_right_pos,
    tp_masthead_iframe_src,
    tp_masthead_image_border_radius,
    tp_masthead_background_slideshow,
    tp_masthead_iframe_placeholder,
    tp_masthead_overlay_type,
    tp_masthead_background_solid_colour,
    tp_masthead_scale_bg
  } = props.attributes;

  let backgroundStyle = {};
  let backgroundWrapperStyle = {};
 
  backgroundStyle = {
    backgroundImage:
      "url(" +
      js_data.guten_path +
      "/includes/gutenberg/masthead-block-ibtr/img/temp_background.jpg)",
  };
  var overlayStyle = (tp_masthead_overlay_type === "solid" ? { backgroundColor: tp_masthead_overlay_colour, opacity: tp_masthead_overlay_opasitiy } : { backgroundImage: tp_masthead_overlay_colour, opacity: tp_masthead_overlay_opasitiy, borderRadius: tp_masthead_image_border_radius });

  backgroundWrapperStyle.left =
    tp_masthead_background_static_left_pos + "%";
  backgroundWrapperStyle.right =
    tp_masthead_background_static_right_pos + "%";
  backgroundStyle.borderRadius = tp_masthead_image_border_radius;
  if(tp_masthead_scale_bg){backgroundStyle.backgroundSize = 'contain'}
  // backgroundWrapperStyle.backgroundColor = tp_masthead_overlay_colour;
  // backgroundStyle.opacity =  tp_masthead_overlay_opasitiy;

  switch (tp_masthead_background_type) {
    case "static":
      if (tp_masthead_background_static_url !== "") {
        backgroundStyle.backgroundImage = `url(${tp_masthead_background_static_url})`;
      }

      return (
        <>
          <div
            className="tp_masthead--background-wrapper"
            style={backgroundWrapperStyle}
          >
            <div
              className={
                tp_masthead_background_static_paralax === true
                  ? "tp_masthead--background tp_masthead--background--fixed"
                  : "tp_masthead--background"
              }
              style={backgroundStyle} alt={tp_masthead_background_static_alt}
            >
              <div className="tp_masthead__overlay" style={overlayStyle}></div>
            </div>
          </div>
         
        </>

      );
    case "slideShow":
      let slideShow = [];


      if (
        tp_masthead_background_slideshow[1].imgURL !== "" &&
        tp_masthead_background_slideshow[2].imgURL !== "" &&
        tp_masthead_background_slideshow[3].imgURL !== ""
      ) {

        slideShow = Object.keys(tp_masthead_background_slideshow).map((key) => (
          <div key={key} className="tp_masthead--slide slick-slide" style={{ background: `url(${tp_masthead_background_slideshow[key].imgURL}) center center / cover no-repeat ` }} alt={tp_masthead_background_slideshow[key].imgAlt}></div>
        ));
      } else {
        slideShow = <div
          className={"tp_masthead--background"}
          style={{ background: `url(${tp_masthead_background_slideshow[1].imgURL}) center center / cover no-repeat` }} alt={tp_masthead_background_slideshow[1].imgAlt}
        ></div>
      }



      return (<>
        <div className="tp_masthead__overlay" style={overlayStyle}></div>

        <div className="tp_masthead--background-wrapper-slider"  >

          {/* <div className="tp_masthead--background-wrapper-slider" style={{ borderRadius: tp_masthead_slider_border_radius }} > */}
          {slideShow}

        </div>




      </>);

    case "solid":
      return null;

    case "video":

      if (tp_masthead_overlay_opasitiy + tp_masthead_overlay_colour !== "") {
        backgroundStyle.opacity = 1 - tp_masthead_overlay_opasitiy;

        backgroundWrapperStyle.backgroundColor = tp_masthead_overlay_colour;

      }
      delete backgroundStyle.backgroundImage;
      tp_masthead_scale_bg ? backgroundStyle.objectFit = 'contain':backgroundStyle.objectFit = 'cover';


      return (
        <div className="tp_masthead--background-wrapper" style={backgroundWrapperStyle}>
          <video style={backgroundStyle} className="tp_masthead--background-video" src={tp_masthead_iframe_src} autoPlay={true} muted={true} loop={true} playsInline webkit-playsinline={true} poster={tp_masthead_iframe_placeholder !== "" ? tp_masthead_iframe_placeholder : null}>

          </video>
          <div className="tp_masthead__overlay" style={overlayStyle}></div>

          {/* <span class="mute-unmute__button">
            <i class="fas vol-btn fa-volume-mute" aria-hidden="true"></i>
          </span> */}
        </div>
      );
  }

};
