import { React } from 'react';
import MastheadIBTRConfig from '../Components/MastheadIBTRConfig';
import { MastheadIBTRSidebar } from '../Components/MastheadIBTRSidebar';
import { BackgroundSwitch } from '../../CommonComponents/BackgroudSwitch';
import { Button } from '@wordpress/components';
import { useBlockProps, InnerBlocks } from '@wordpress/block-editor';
import '../../additionalEditorScripts'


export default function Edit({ attributes, setAttributes }) {
	// Protentaly look into this:
	// https://wp-gb.com/guide/
	// blockprops contains data about this block, id numbers, classnames ECT
	const blockprops = useBlockProps()

	//Changes the state based on the payload verable. Used throughout the block as a callback function. 
	const changeBlockConfig = (payload) => {
		let target = { ...attributes }
		let values = Object.assign(target, payload);
		console.log(payload);
		if (Object.keys(payload)[0] === "tp_masthead_background_type" && Object.values(payload)[0] !== 'slideShow') {
			const slideShowStop = new Event('slideShowStop');
			document.dispatchEvent(slideShowStop);
		}
		
		setAttributes(values)
		//Reset the background colour if background option changes

		if (Object.keys(payload)[0] === "tp_masthead_background_type" && Object.values(payload)[0] !== 'solid') {

			payload = { tp_masthead_background_solid_colour: '' };
			let values = Object.assign(target, payload);

			setAttributes(values)

		}

	}


	// Select the masthead block, this is called immediately after a template has been selected
	const selectMasthead = () => {

		let mastheadLoaded = false;
		let mastheadLoadedInterval = setInterval(function () {
			if (document.getElementById(`block-${blockprops['data-block']}`)) {
				mastheadLoaded = true;
			}
			if (mastheadLoaded) {
				clearInterval(mastheadLoadedInterval);
				wp.data.dispatch('core/block-editor').selectBlock(blockprops['data-block']);
				///Open sidebar if not allready open
				if (!wp.data.select('core/edit-post').isEditorSidebarOpened()) {
					wp.data.dispatch('core/edit-post').openGeneralSidebar('edit-post/block');
				}
			}
		}, 500);
	};

	/* Function that will either show the template picker or add the innerblock containing heading and button group to the masthead block. 
	This is based on weather the template has been selected by the user*/
	// const renderMastheadContent = () => {

	// 	return (attributes.tp_masthead_template !== "") ?
	// 		<MastheadMainContent/>
	// 		: <MastheadIBTRConfig attributes={attributes} callBack={changeBlockConfig} />

	// }
	const renderMastheadContent = () => {

		return (attributes.tp_masthead_template === "") ?
			 <MastheadIBTRConfig attributes={attributes} callBack={changeBlockConfig} />:   <InnerBlocks

			 template={[

				 ['gutenberg/masthead-heading', {}],
				 ['gutenberg/masthead-button-group', {}]


			 ]}
			 templateLock="insert"
		 // allowedBlocks={['core/heading']}
		 />

	}


	let mastheadSectionStyles = {
		justifyContent: 'center',

	}
	// If solid colour has been chosen then assign the hex value to the masthead background colour CSS valable
	//  attributes.tp_masthead_background_type ==='solid' ? mastheadSectionStyles.backgroundColor = attributes.tp_masthead_background_solid_colour : mastheadSectionStyles.backgroundImage = attributes.tp_masthead_background_solid_colour;
	attributes.tp_masthead_background_solid_colour ? mastheadSectionStyles.backgroundColor = attributes.tp_masthead_background_solid_colour : null;
	// Align the innerblock to the center, flex-start or flex-end
	if (attributes.tp_masthead_content_alignment !== 'center') {
		mastheadSectionStyles.justifyContent = (attributes.tp_masthead_content_alignment == "top") ? 'flex-start' : 'flex-end';
	}
	

	// If the template has been chosen by the user render the following content. 
	if (attributes.tp_masthead_template !== "") {
		return (
			<section {...blockprops} className="align-wide tp_masthead" style={mastheadSectionStyles} >

				{/* BackgroundSwitch changes the elements on the page based on weather solid colour, static image, slideshow or video is chosen */}
				<BackgroundSwitch attributes={attributes} />
				{/* The sidebar containing all the configration components */}
				<MastheadIBTRSidebar attributes={attributes} callBack={changeBlockConfig} />

				<div className={'container tp_masthead--container'}  >
					{/* Innerblock */}
					{renderMastheadContent()}
				</div>
				{/* Show the reconfigure button to allow for the masthead sidebar to be reselected */}

				<Button className='button button-reconfigure' onClick={() => { selectMasthead() }}>Reconfigure Masthead</Button>
			</section>
		);
	}
	// This will show the template picker
	return (<div>{renderMastheadContent()}</div>);
}





