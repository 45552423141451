import React from 'react'
import { registerBlockType } from '@wordpress/blocks';
import { __ } from '@wordpress/i18n';

registerBlockType('gutenberg/masthead-title-wrapper', {
    apiVersion: 2,
    title: __('masthead-title-wrapper', 'gutenberg'),

    edit: function () {
        return (
            <div className='tp_masthead--title-wrapper' ></div>
        );
    },

    save: function () {
        return (
            <div className='tp_masthead--title-wrapper' ></div>
        )
    }
})
