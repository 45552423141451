import { useEffect } from 'react';
import { ColourPickerComponent } from './ColourPickerComponent';
import { OverlaySliderComponent } from './OverlaySliderComponent';
import { MediaUploader } from './MediaUploadComponent';
import { IframeComponent } from './VideoComponent';
import { Panel, PanelBody, PanelRow, ToggleControl } from '@wordpress/components';
import { BackgroundPositionComponent } from './BackgroundPositionComponent';
import { BackgroundBorderRadusComponent } from './BackgroundBorderRadusComponent';
import { MastheadSliderSidebar } from '../masthead-block-ibtr/Components/MastheadSliderSidebar';
import { ParrallaxToggle } from './ParrlaxToggle';


export const BackgroundOptions = (props) => {
    const { tp_masthead_background_type,
        tp_masthead_background_static_paralax,
        tp_masthead_background_solid_colour,
        tp_masthead_overlay_colour,
        tp_masthead_background_static_left_pos,
        tp_masthead_background_static_right_pos,
        tp_masthead_overlay_opasitiy,
        tp_masthead_slider_amount,
        tp_masthead_iframe_src,
        tp_masthead_iframe_placeholder,
        tp_masthead_background_static_url,
        tp_masthead_background_slideshow,
        tp_masthead_scale_bg} = props.attributes;

    useEffect(() => {
        function handleScroll() {
            if(tp_masthead_background_type !== 'static'){return}
            let windowHeight = window.outerHeight;
            let parent = document.querySelector('.tp_masthead--background-wrapper');
            let headerHeight = document.querySelector('.interface-interface-skeleton__header').scrollHeight;
            let adminBarHeight = document.querySelector('#wpadminbar').scrollHeight;
            //workout how far masthead is from top of screen
            let scroll = (parent.getBoundingClientRect().top - (headerHeight+ adminBarHeight)) * -1; //multiply by -1 to invert
            let translate3d = Math.round(scroll / 2); //Rounding for better smoothness when scrolling
            translate3d = translate3d < 0 ? 0 : translate3d;
            let $hero = document.querySelector(".tp_masthead--background--fixed");
    
            if (scroll < windowHeight) {            
                let effect = "translate3d(0px," + translate3d + "px, 0px)";
                $hero.style.transform = effect
            }
        }

        if( tp_masthead_background_static_paralax === true ) {
            document.querySelector('.interface-interface-skeleton__content').addEventListener("scroll", handleScroll);
        } else {
            let masthead_image = document.querySelector(".tp_masthead--background");
            document.querySelector('.interface-interface-skeleton__content').removeEventListener("scroll", handleScroll);

            //Reset image transform 
            if( masthead_image ) {
                masthead_image.style.transform = "translate3d(0px, 0px, 0px)";
            }
        }

        return () => {
             document.querySelector('.interface-interface-skeleton__content').removeEventListener("scroll", handleScroll);
        };
    },[tp_masthead_background_static_paralax])

    switch (tp_masthead_background_type) {

        case 'static':

            return <Panel>
                <PanelBody title={'Select Your Background Image'}>
                    <h4 className='tp_masthead-sidebar-help'>Elements Without Text will not render</h4>
                    <MediaUploader currentSelection={tp_masthead_background_type}
                        callBack={props.callBack}
                        static_url={tp_masthead_background_static_url}
                        slideshow_arr={tp_masthead_background_slideshow}
                        btnLabel={"Current Background Image"}
                    />
                    <PanelRow>
                        <ParrallaxToggle currentToggle={tp_masthead_background_static_paralax} callBack={props.callBack} />
                    </PanelRow>
                </PanelBody>
                <PanelBody title={'Background Colour Options '} initialOpen={false}>
                    <ColourPickerComponent target={'tp_masthead_background_solid_colour'} callBack={props.callBack} title={'Background Colour'} current={tp_masthead_background_solid_colour}/>


                </PanelBody>
                <PanelBody title={'Overlay Options'} initialOpen={false}>
                    <OverlaySliderComponent currentSelection={tp_masthead_overlay_opasitiy} callBack={props.callBack} title={'Overlay Opasity'} />
                    <ColourPickerComponent target={'tp_masthead_overlay_colour'} callBack={props.callBack} title={'OverLay Colour'} current={tp_masthead_overlay_colour}/>
                </PanelBody>
                <PanelBody title={'Background Position'} initialOpen={false}>
                    <BackgroundPositionComponent
                        currentRight={tp_masthead_background_static_right_pos}
                        currentLeft={tp_masthead_background_static_left_pos}
                        callBack={props.callBack}
                        title={'Change the position of the background Image'}
                        callBackTarget={'tp_masthead_background_static_'}
                        scale_bg ={tp_masthead_scale_bg}
                    />
                </PanelBody>
                <PanelBody title={'Background Image Border Radius'} initialOpen={false}>
                    <BackgroundBorderRadusComponent
                        title={'Image Border Radus Options'}
                        callBack={props.callBack}
                        callBackTarget={'tp_masthead_image_border_radius'}
                    />
                </PanelBody>

            </Panel>
        case 'slideShow':

            return <Panel>

                <MastheadSliderSidebar
                    callBack={props.callBack}
                    slideAmount={tp_masthead_slider_amount}
                    static_url={tp_masthead_background_static_url}
                    slideshow_arr={tp_masthead_background_slideshow}
                />
                <PanelBody title={'Slideshow Overlay'} initialOpen={false}>
                    <OverlaySliderComponent currentSelection={tp_masthead_overlay_opasitiy} callBack={props.callBack} title={'Slideshow Overlay Opasity'} />
                    <ColourPickerComponent target={'tp_masthead_overlay_colour'} callBack={props.callBack} title={'Slideshow OverLay Colour'} current={tp_masthead_overlay_colour}/>
                </PanelBody>
          

            </Panel>
        case 'solid':
            return <Panel>
                <PanelBody title={'Solid Colour Configuration'}>
                    <ColourPickerComponent target={'tp_masthead_background_solid_colour'} callBack={props.callBack} title={"Background Colour"} current={tp_masthead_background_solid_colour}/>
                </PanelBody>
            </Panel>;
        case 'video':
            return <Panel>
                <PanelBody title={'Masthead Video Configuration'}>
                    <IframeComponent callBack={props.callBack} current_iframe_src={tp_masthead_iframe_src} current_placeholder={tp_masthead_iframe_placeholder} />
                </PanelBody>
                <PanelBody title={'Video Overlay'} initialOpen={false}>
                    <OverlaySliderComponent currentSelection={tp_masthead_overlay_opasitiy} callBack={props.callBack} title={'Video Overlay Opasity'} />
                    <ColourPickerComponent target={'tp_masthead_overlay_colour'} callBack={props.callBack} title={'Video OverLay Colour'} current={tp_masthead_overlay_colour}/>
                </PanelBody>
              
                <PanelBody title={'Video Border Radius'} initialOpen={false}>
                    <BackgroundBorderRadusComponent
                        title={'Video Border Radus Options'}
                        callBack={props.callBack}
                        callBackTarget={'tp_masthead_image_border_radius'}
                    />
                </PanelBody>
                <PanelBody title={'Video Position'} initialOpen={false}>
                    <BackgroundPositionComponent
                        currentRight={tp_masthead_background_static_right_pos}
                        currentLeft={tp_masthead_background_static_left_pos}
                        callBack={props.callBack}
                        title={'Change the position of the Video'}
                        callBackTarget={'tp_masthead_background_static_'}
                    />
                </PanelBody>
                <PanelBody title={'Background Colour Options '} initialOpen={false}>
                    <ColourPickerComponent target={'tp_masthead_background_solid_colour'} callBack={props.callBack} title={'Background Colour'} current={tp_masthead_background_solid_colour}/>


                </PanelBody>
            </Panel>;
    }
}