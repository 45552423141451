

import { registerBlockType } from '@wordpress/blocks';
import { __ } from '@wordpress/i18n';

import './scss/styles.scss';
import './scss/editor.scss';

import Edit from './edit';

import Save from './save';

//import '../../additionalEditorScripts'


registerBlockType('gutenberg/masthead-block-ibtr', {
	apiVersion: 2,
	title: __('TP Masthead block', 'gutenberg'),
	supports: { html: false,		multiple: false,	},
	example: {},

	attributes: {
		multiple: false,

		lock: {
			remove: true,
			move: true,

		},
		tp_masthead_content_alignment:{type: 'string', default:'center'},
		tp_masthead_image_border_radius:{type: 'string', default:'0% 0% 0% 0%'},
		tp_masthead_template: {
			type: "string",
			default: ""
		},
		tp_masthead_background_type: {
			type: "string",
			default: "static",
		},
		tp_masthead_background_solid_colour:{type:"string",
		default:""},

		tp_masthead_background_static_url: {
			type: "string",
			 default: ''
		},
		tp_masthead_background_static_left_pos: {
			type: "number",
			 default: 0
		},
		tp_masthead_background_static_right_pos: {
			type: "number",
			 default: 0
		},
		tp_masthead_background_slider_left_pos: {
			type: "number",
			 default: 0
		},
		tp_masthead_background_slider_right_pos: {
			type: "number",
			 default: 0
		},
		tp_masthead_background_static_border_radius:{type:"number", default:0},
		tp_masthead_background_slider_border_radius:{type:"number", default:0},
		tp_masthead_background_static_paralax:{type: 'boolean', default:false},
		tp_masthead_background_slideshow: {
			type: 'object',
			default:{1:{imgURL:'', imgAlt:""},2:{imgURL:'', imgAlt:""},3:{imgURL:'', imgAlt:""}}
			
		},
		tp_masthead_background_static_id: {
			type: "number",
			default: 0
		},
		tp_masthead_background_static_alt: {
			type: "string"
		},
		// tp_masthead_has_overlay: {
		// 	type: "boolean",
		// 	default: false
		// },
		tp_masthead_overlay_type: {
			type: "string",
			default: 'solid'
		},
		tp_masthead_overlay_colour: {
			type: "string",
			default: ''
		},
		tp_masthead_iframe_src: {
			type: "string",
			default: ''
		},
		tp_masthead_iframe_placeholder: {
			type: "string",
			default: ''
		},
		tp_masthead_overlay_opasitiy: {
			type: 'number',
			default: 0.5
		},
		tp_masthead_slider_amount:{
			type:'number',
			default:3
		},
		tp_masthead_text_spacing:{
			type:"object",
			default:{prefix:{},title:{},subtitle:{}}
		},
		tp_masthead_scale_bg:{
			type:'boolean',
			default:false
		}
	},

	providesContext:{
		'mastheadText/spacing':'tp_masthead_text_spacing',
	},
	edit: Edit,

	save: Save
});
