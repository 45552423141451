import { RangeControl, ToggleControl } from '@wordpress/components';
import React, { useState } from 'react';


export const BackgroundPositionComponent = (props) => {
    const [rightValue, setRightValue] = useState(0);
    const [leftValue, setLeftValue] = useState(0);
    const [bgScale, setBgScale] = useState(props.tp_masthead_scale_bg);

    const maxPos = 90;

    const positionCap = (leftOrRight, posVal) => {

        leftOrRight === "right" ? setRightValue(posVal)
        : 
        setLeftValue(posVal);
        let callBackObj = {};
        callBackObj[props.callBackTarget+leftOrRight+'_pos'] = posVal;
        props.callBack(callBackObj);

    }
    const changeBgScale = (change)=>{setBgScale(change);props.callBack({'tp_masthead_scale_bg':change})}
    return (<>
        <h4>{props.title}</h4>
        <RangeControl

            label={'Background Right Position'}
            value={props.currentRight}
            onChange={(rightValue) => positionCap('right', rightValue)}

            min={0}
            // max={maxPos - leftValue}
            max={maxPos}
            step={10}
            marks={[
                {
                    value: 0,
                    label: '0%',
                },
                {
                    value: 20,
                    label: '20%',
                },
                {
                    value: 40,
                    label: '40%',
                },
                {
                    value: 60,
                    label: '60%',
                },
                {
                    value: 80,
                    label: '80%',
                },

            ]}
        />
        <RangeControl

            label={'Background Left Position'}
            value={props.currentLeft}
            onChange={(leftValue) => positionCap('left', leftValue)}
            min={0}
            // max={maxPos - rightValue}
            max={maxPos}
            step={10}
            marks={[
                {
                    value: 0,
                    label: '0%',
                },
                {
                    value: 20,
                    label: '20%',
                },
                {
                    value: 40,
                    label: '40%',
                },
                {
                    value: 60,
                    label: '60%',
                },
                {
                    value: 80,
                    label: '80%',
                },

            ]}
            
        />
        <ToggleControl
            label="Scale Background?"
            //help=
            checked={bgScale}
            onChange={((change) => changeBgScale(change))}
        />
    </>
    );
}