import { Button } from '@wordpress/components';
import { MediaUpload, MediaUploadCheck } from '@wordpress/block-editor';
import { ToggleControl } from '@wordpress/components';

const ALLOWED_MEDIA_TYPES = ['image'];

export const MediaUploader = (props) => {
	let mediaId;
	const changeBackground = (media) => {
		switch (props.currentSelection) {
			case 'static': return props.callBack({ 'tp_masthead_background_static_url': media.url, 'tp_masthead_background_static_alt': media.alt });
			case 'slideshow': return props.callBack(props.slideNumber, media.url, media.alt);
			case 'video': return props.callBack({ 'tp_masthead_iframe_placeholder': media.url });
		}
	}
	return (
		<MediaUploadCheck>
			<MediaUpload

				onSelect={(media) => { changeBackground(media) }}
				allowedTypes={ALLOWED_MEDIA_TYPES}
				value={mediaId}
				multiple={false}
				render={({ open }) => (
					<Button
						className={mediaId == 0 ? 'button editor-post-featured-image__toggle' : 'button editor-post-featured-image__preview'}

						onClick={open}>Open Media Library
						{props.static_url != '' &&
							<div style={{ display: 'flex', flexWrap: 'wrap' }}
							>
								<img style={{ width: '100%', height: '100%', backgroundSize:'cover' }} src={props.static_url} />
							</div>

						}

					</Button>

				)}


			/>


		</MediaUploadCheck>


	);

}

