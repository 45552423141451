import {React} from 'react'
import { registerBlockType } from '@wordpress/blocks';
import { __ } from '@wordpress/i18n';
import {  InnerBlocks } from '@wordpress/block-editor';

registerBlockType('gutenberg/masthead-button-group', {
    apiVersion: 2,
    title: __('masthead-button-group', 'gutenberg'),
    parent: ['gutenberg/masthead-block-ibtr'],
    attributes: {
        buttonAmount: {
            type: 'number',
        },

     },
    
    
    /////Only 2 buttons
    // https://wordpress.stackexchange.com/questions/344957/how-can-you-reset-innerblock-content-to-base-template
    // https://wordpress.stackexchange.com/questions/344792/creating-elements-innerblocks-via-rangecontrol/344975#344975
    edit: function (props,classNames) {
     
        return (
            <div className='tp_masthead--buttons' >
        
                <InnerBlocks
                    template={[[ 'core/buttons', {}, [[ 'core/button', {className:"tp_button" } ],[ 'core/button', { className:'tp_button'} ]]]]}
                    templateLock='insert'
                 
                />
            </div>
        );
    },

    save: function () {
        return (
            <div className='tp_masthead--buttons'>
                <InnerBlocks.Content />

            </div>
        )
    }
})
