import { FocusableIframe, TextControl, Button } from '@wordpress/components';
import { useState } from 'react';
import { MediaUploader } from './MediaUploadComponent';
import { Panel, PanelBody } from '@wordpress/components';


// https://github.com/WordPress/gutenberg/blob/trunk/packages/block-library/src/embed/edit.js
// https://stackoverflow.com/questions/5157377/show-youtube-video-source-into-html5-video-tag
export const IframeComponent = (props) => {
  const [iframeURL, setIframeURL] = useState(props.current_iframe_src);
  const [iframeURLVaild, setiframeURLVaild] = useState(false);
  const checkURLVailidity = (iframeURL) => {
    let loweredIframeURL = iframeURL.toLowerCase();
    if (!loweredIframeURL.includes('mp4') && !loweredIframeURL.includes('mkv') && !loweredIframeURL.includes('mov') ) {
      alert('Link provided is not valid. Please enter a valid link then try again.');
      return;
    }
    try {
      new URL(iframeURL);
    } catch (e) {
      alert('Link provided is not valid. Please enter a valid link then try again.');
      return;
    }
    setiframeURLVaild(true)
  }

  return (<Panel>
    <PanelBody title='Video URL'>
      <TextControl
        label="Paste Video URL Here"
        value={iframeURL}
        onChange={(change) => { setIframeURL(change); if (iframeURLVaild) { setiframeURLVaild(false) } }}
      />
      <Button className="button" onClick={() => checkURLVailidity(iframeURL)}>Validate</Button>

    </PanelBody >
    {iframeURLVaild && <PanelBody title="Video Preview">
      <FocusableIframe
        src={iframeURL}
        autoPlay={false}
      />
      <Button className="button" onClick={() => props.callBack({ 'tp_masthead_iframe_src': iframeURL })}>Confirm</Button>
    </PanelBody>}
    <PanelBody title='Video Placeholder'>
      <MediaUploader currentSelection={'video'} callBack={props.callBack} static_url={props.current_placeholder} />
    </PanelBody>
  </Panel>
  );
}
