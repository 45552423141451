import { RangeControl } from '@wordpress/components';

export const OverlaySliderComponent = (props) => {
    let sliderValue;
    const sliderChange = (sliderValue) => { props.callBack({'tp_masthead_overlay_opasitiy': sliderValue}); }
    return (<>
        <h4>{props.title}</h4>
        <RangeControl

            label={'Overlay Opacity'}
            value={props.currentSelection}
            onChange={(sliderValue) => sliderChange(sliderValue)}
            min={0}
            max={1}
            step={0.01}
        // defaultValue={props.currentOpastity}
        />
    </>
    );
}