////This function will  assign all blocks the perant as 'gutenberg/masthead-block-ibtr. This way the blocks are not insertable unless inside the masthead 
//Cheers: https://wordpress.stackexchange.com/questions/326959/gutenberg-disallow-certain-custom-blocks-but-keep-all-core-blocks
const unrestrictedBlocksArr = ['gutenberg/masthead-block-ibtr'];

function removeAttributes(settings, name) {
    // Below will allow Woocommerce blocks to be added into the page
    if ( unrestrictedBlocksArr.includes(name) || name.includes('woocommerce')) {
        return settings;
    }
    
    // Object.assign(settings, {
    //     parent: [
    //         'gutenberg/masthead-block-ibtr'

    //     ],
    //     example: {},



    // });
    ///Remove edit as HTML option on all blocks. 
    return lodash.assign({}, settings, {
        supports: lodash.assign({}, settings.supports, {
            html: false,
            defaultStylePicker: false,

            spacing: {
                margin: true,
                padding: true,
            }
        }),
    });
}

wp.hooks.addFilter(
    'blocks.registerBlockType',
    'gutenberg/masthead-block',
    removeAttributes
);

wp.domReady(() => {
    if( wp.data.dispatch('core/edit-post')){
    wp.data.dispatch('core/edit-post').removeEditorPanel('template');
    }

});