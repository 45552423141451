import React from 'react'
import { registerBlockType } from '@wordpress/blocks';
import { __ } from '@wordpress/i18n';
import {  InnerBlocks } from '@wordpress/block-editor';

registerBlockType('gutenberg/masthead-heading', {
    apiVersion: 2,
    title: __('masthead-heading', 'gutenberg'),
    parent: ['gutenberg/masthead-block-ibtr'],

    edit: function () {
        return (
            <div className='tp_masthead--heading' >
                <InnerBlocks

                    template={[
                        ['core/paragraph', { placeholder: 'Enter Your Title Prefix Here', className: 'tp_masthead--heading-prefix', }],
                        ['core/heading', { placeholder: 'Enter Your Title Here', level: 1, className: 'tp_masthead--heading-title', }],
                        ['core/paragraph', { placeholder: 'Enter Your Title Subtitle Here', className: 'tp_masthead--heading-subtitle', }],

                    ]}
                    templateLock="insert"
                />
            </div>
        );
    },

    save: function () {
        return (

            <div className='tp_masthead--heading' >
                <InnerBlocks.Content />
            </div>
        )
    }
})
