wp.domReady( function() {

wp.blocks.unregisterBlockStyle( 'core/button', 'outline' );
wp.blocks.unregisterBlockStyle( 'core/button', 'fill' );
wp.blocks.registerBlockStyle( 'core/button', {
    name: '-primary',
    label: 'Primary Colour Button',
    inline_style:'.is-style--primary'
 } );
 wp.blocks.registerBlockStyle( 'core/button', {
    name: '-secondary',
    label: 'Secondary Button',
    inline_style:'is-style--secondary'
 } );
wp.blocks.registerBlockStyle( 'core/button', {
    name: '-white',
    label: 'White Button',
    inline_style:'is-style--white'
 } );
 

wp.blocks.registerBlockStyle( 'core/button', {
    name: '-ghost',
    label: 'Ghost Button',
    inline_style:'is-style--ghost'
 } );
})
