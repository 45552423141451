import { ToggleControl } from '@wordpress/components';
import { useState,useEffect } from 'react'

export const ParrallaxToggle = (props) => {

    const parallaxStart = new Event('parallaxStart');
    
    const [currentToggle, setCurrentToggle] = useState(props.currentToggle);
    useEffect(()=>{if(currentToggle){document.dispatchEvent(parallaxStart)}},[currentToggle])
    return(<ToggleControl
        label={"Add Paralax Effect To Background Image"}
        checked={currentToggle}
        
        onChange={(change) => { setCurrentToggle(change);props.callBack({'tp_masthead_background_static_paralax':change})}}
    />)
}

//document.dispatchEvent(parallaxStart)