import React from 'react';

import { Button } from '@wordpress/components';
import ReactTooltip from 'react-tooltip';
const mastheadOptions = {
    classic: { 
        name: 'Classic', 
        tip: 'Classic Masthead 2 CTA buttons and a choice of diffrent background types', 
        callBack: {
            tp_masthead_template:'classic'
        }, 
        img: 'classic.jpg' 
    },
    twobytwo: { 
        name: 'Two By Two', 
        tip: 'Masthead with two columns 1 for images and 1 for CTA buttons and a choice of diffrent background types', 
        callBack: {
            tp_masthead_template:'twobytwo','tp_masthead_background_static_left_pos': 50},
            img: 'twobytwo.jpg' 
    },
    thirtyBySeventy: { 
        name: 'Thirty By Seventy', 
        tip: 'Masthead with two columns, first column is smaller than the second', 
        callBack: {
            tp_masthead_template:'thirtyBySeventy','tp_masthead_background_static_left_pos': 30
        },  
        img: '30by70.jpg' 
    },
    SeventyByThirty: { 
        name: 'Seventy By Thirty', 
        tip: 'Masthead with two columns, first column is larger than the second. Title will be set at the bottom', 
        callBack: {
            tp_masthead_template:'thirtyBySeventy','tp_masthead_background_static_right_pos': 30, 'tp_masthead_content_alignment':'bottom'
        },  
        img: '70by30.jpg' 
    },
}
const MastheadIBTRConfig = (props) => {
    return (
        <div className="config">
            <div className="config__body">
                <h2 className="config__title">Choose Masthead Type:</h2>
                <div className="config__button-group">
                    <ReactTooltip delayHide={500} />
                    {Object.keys(mastheadOptions).map((x, ix) => {
                        return (
                            <Button
                                group={"tp_masthead_template"}
                                key={ix}
                                value={mastheadOptions[x].name}
                                className="button config__button"
                                data-tip={mastheadOptions[x].tip}
                                data-delay-hide='1000'
                                color="royalblue"
                                onClick={(e) => props.callBack(mastheadOptions[x].callBack)}
                            >
                                <img className="config__button-preview" src={js_data.guten_path + '/includes/gutenberg/masthead-block-ibtr/img/' + mastheadOptions[x].img} />
                            </Button>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default MastheadIBTRConfig